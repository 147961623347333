<template>
  <div>
    <b-overlay :show="loading">
      <ag-grid-vue
        :gridOptions="gridOptions"
        class="ag-theme-balham ag-grid-table mb-1"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        @grid-ready="onGridReady"
        :rowData="items"
        rowSelection="single"
        colResizeDefault="shift"
        :animateRows="false"
        :floatingFilter="true"
        :pagination="true"
        :paginationPageSize="paginationPageSize"
        :rowClassRules="rowClassRules"
        @selection-changed="onSelectionChanged"
        @first-data-rendered="onFirstDataRendered"
        :alwaysShowVerticalScroll="true"
        :suppressPaginationPanel="true"
      >
      </ag-grid-vue>
    </b-overlay>
    <b-card-text>
      <b-row>
        <b-col cols="6" md="6">
          <span class="text-nowrap text-bold">
            <strong>
              Mostrando
              {{ currentPage * paginationPageSize - (paginationPageSize - 1) }}
              -
              {{
                items.length - currentPage * paginationPageSize > 0
                  ? currentPage * paginationPageSize
                  : items.length
              }}
              de {{ items.length }} Registros
            </strong>
          </span>
        </b-col>
        <b-col cols="6" md="6">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalPages"
            :per-page="1"
            align="right"
            size="sm"
            class="my-0"
          >
            <template #prev-text>
              <feather-icon size="18" icon="ChevronLeftIcon" />
            </template>
            <template #next-text>
              <feather-icon size="18" icon="ChevronRightIcon" />
            </template>
            <template #last-text>
              <feather-icon size="18" icon="ChevronsRightIcon" />
            </template>
            <template #first-text>
              <feather-icon size="18" icon="ChevronsLeftIcon" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </b-card-text>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import {
  BCard,
  BCardText,
  BOverlay,
  BPagination,
  BRow,
  BCol,
} from "bootstrap-vue";
export default {
  components: {
    BCard,
    BCardText,
    AgGridVue,
    BOverlay,
    BPagination,
    BRow,
    BCol,
  },
  props: ["items", "loading"],
  data() {
    return {
      // grid
      gridOptions: {
        localeText: {
          noRowsToShow: "No hay filas para mostrar",
          contains: "Contiene",
          notContains: "No Contiene",
          equals: "Es Igual",
          notEqual: "No es Igual",
          startsWith: "Comienza con",
          endsWith: "Termina con",
          filterOoo: "Filtral...",
        },
      },
      rowClassRules: null,

      gridApi: null,
      selectedRows: [],
      defaultColDef: {
        sortable: false,
        //editable: false,
        floatingFilter: true,
        resizable: true,
        suppressMenu: true,
      },
      columnDefs: [
        {
          headerName: "FECHA",
          field: "fecha",
          filter: false,
          checkboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          headerCheckboxSelection: false,
          minWidth: 150,
          maxWidth: 150,
        },
        {
          headerName: "ESTADO",
          // field: "estado",
          cellRenderer: function (param) {
            let clase = "";
            if (param.data.estado == "Pendiente") {
              clase = "badge-warning";
            } else if (param.data.estado == "Aceptado") {
              clase = "badge-success";
            } else {
              clase = "badge-danger";
            }
            return /*html*/ `<span class="badge badge-pill ${clase} text-uppercase">${param.data.estado}</span>`;
          },
          valueGetter: (param) => {
            return param.data.estado;
          },
          filter: true,
          minWidth: 150,
          maxWidth: 150,
          wrapText: true,
        },
        {
          headerName: "REFERENCIA",
          field: "referencia",
          filter: true,
          minWidth: 200,
          maxWidth: 200,
        },
        {
          headerName: "FORMAS DE PAGO",
          field: "transaccion.metodo_pagos.descripcion",
          filter: false,
          minWidth: 240,
          maxWidth: 240,
          wrapText: true,
        },
        {
          headerName: "DETALLE",
          headerComponentParams: {
            template: /*html*/ `<table width="100%" style="border-collapse">
                <tr>
                    <th style="font-size: 13px; font-weight: 600; width: 460px; max-width: 460px;">DESCRIPCION</th>
                    <th style="font-size: 13px; font-weight: 600; width: 80px; text-align: right;">CANT.</th>
                    <th style="font-size: 13px; font-weight: 600; width: 80px; text-align: right;">COSTO REF.</th>
                    <th style="font-size: 13px; font-weight: 600; width: 80px; text-align: right;">TOTAL</th>
                </tr>
            </table>`,
          },
          cellRenderer: function (param) {
            let detalle = /*html*/ `<table width="100%" style="border-collapse: collapse;">`;
            for (
              let index = 0;
              index < param.data.transaccion.detalle_transaccion.length;
              index++
            ) {
              detalle += /*html*/ `<tr>
                    <td style="width: 460px; max-width: 460px; overflow-x: hidden;">
                        ${param.data.transaccion.detalle_transaccion[index].producto.descripcion}
                    </td>
                    <td style="width: 80px; text-align: right;">${param.data.transaccion.detalle_transaccion[index].cantidad}</td>
                    <td style="width: 80px; text-align: right;">${param.data.transaccion.detalle_transaccion[index].costo}</td>
                    <td style="width: 80px; text-align: right;">${param.data.transaccion.detalle_transaccion[index].subtotal}</td>
                </tr>`;
            }
            detalle +=
              /*html*/
              `<tr style="border-top: 1px solid #e5e5e5;">
                    <td colspan="3" style="text-align: right; font-weight: 600;">TOTAL</td>
                    <td style="text-align: right; font-weight: 600;">${param.data.transaccion.subtotal}</td>
                </tr>
            </table>`;
            return detalle;
          },
          autoHeight: true,
          suppressSizeToFit: true,
          filter: false,
          minWidth: 700,
          maxWidth: 700,
        },
        {
          headerName: "RESPONSABLE",
          field: "nombre_responsable",
          filter: true,
          minWidth: 240,
          wrapText: true,
        },
        {
          headerName: "C.I. COTIZADOR",
          field: "carnet_identidad_responsable",
          filter: false,
          minWidth: 200,
          maxWidth: 200,
        },
      ],
      rowClassRules: { "row-red": "data.estado === 0" },
      searchQuery: "",
    };
  },
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 25;
    },
    totalPages() {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages();
      else return 0;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1);
      },
    },
  },
  created() {},
  methods: {
    onGridReady(params) {
      params.api.sizeColumnsToFit();
    },
    onFirstDataRendered(params) {
      params.api.sizeColumnsToFit();
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },
    onSelectionChanged() {
      let selectedRows = this.gridApi.getSelectedRows();
      this.selectedRows = selectedRows;
      if (this.selectedRows != "") {
        this.$emit("selectItemVenta", this.selectedRows[0], false);
      } else {
        this.selectedRows = [];
        this.$emit("selectItemVenta", this.selectedRows, true);
      }
    },
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
  },
};
function numberFormatter(params) {
  return Number(params.value).toFixed(2);
}
</script>

<style lang="scss">
@import "@/assets/scss/components/agGridStyle.scss";
.ag-theme-balham .row-red {
  color: #ea5455 !important;
}

.ag-cell:nth-child(5) .ag-cell-wrapper {
  display: block;
}
</style>
