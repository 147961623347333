<template>
  <b-modal
    id="modal-compra-venta"
    ref="modal-compra-venta"
    :title="titulo"
    no-close-on-backdrop
    no-close-on-esc
    size="xl"
    scrollable
    body-text-variant="dark"
    ok-title="Verificar en SIAF"
    cancel-title="Renviar Factura"
    button-size="sm"
    ok-only
    hide-footer
    v-model="mostradoModal"
    @close="$emit('cerrar')"
  >
    <slot></slot>
  </b-modal>
</template>

<script>
export default {
  name: "ModalCompraVenta",
  props: {
    mostrado: {
      type: Boolean,
      default: false,
    },
    titulo: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      mostradoModal: false,
    };
  },
  watch: {
    mostrado: function (value) {
      this.mostradoModal = value;
    },
  },
};
</script>

<style>
</style>